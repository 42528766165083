import React,{useState} from 'react';
import Header from '../components/Header'
import * as Yup from 'yup';
import { HStack, Box, Image ,Text,Button} from '@chakra-ui/react';
import {InputText,Form,SelectFieldSearch,SelectField,MultiSelectField, RadioInput} from '../components/form'
import { toast } from "react-toastify";
import { fetchWrapper } from '../helpers';

const SendMessage = ()=>{

	const [formData, setFormData] = useState({
    to: '',
    message: '',
    type: '1',
   
    
    
    
  });
	const FormSchema = Yup.object().shape({
   	to: Yup.string().required('Required'),
 	  message: Yup.string().required('Required'),
 		
	 	
	});
	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	    let url = `${process.env.REACT_APP_API_URL}/send`;
	    
	    if(values.type == '2'){
	    	 url = `${process.env.REACT_APP_API_URL}/send-sms`;
	    }   
	    if(values.type == '3'){
	    	 url = `${process.env.REACT_APP_API_URL}/send-whatsapp`;
	    }

	    
  		const dataM = await fetchWrapper.postSend(url,data)
  		.then(rs => {


  			toast(`Message has been sent `, {
		       type: "info"
		    });		
  		})
  		.catch(e => {

  			console.log(e)
  			toast(e, {
		       type: "error"
		    });		

  		})
 		
  		
	}
	const typeList = [
		{id : '1' , name:'Smart Message'},
		{id : '2' , name:'SMS'},
		{id:'3', name: 'WhatsApp'}
	]
	return (
		<>
			<Header/>

			<Box>
				 <div className="body_section">

				 		<HStack justifyContent="space-between">
				          <Box  width="30%">
				            	<Box>
					            	<Text fontSize="32px" fontWeight="300" color="#000000" my="0px" fontFamily="'Inter', sans-serif">Send Message</Text>
					          	</Box>
				          
				            	<Form
		            enableReinitialize
		            validationSchema={FormSchema}
		            initialValues={formData}
		            onSubmit={onSubmit}
				>
							<Box mb="10px">
								<InputText name="to" title="Mobile Number" placeholder="Mobile Number"  />
							</Box>
							<Box mb="10px">
								<InputText name="message" title="Message" placeholder="Message"  />
							</Box>

							<Box mb="10px">
								<RadioInput name="type" list={typeList} title="Send Option" placeholder="Send Option"  />
							</Box>
							

					<Button py="10px" px="25px" color="#fff" bg="#2BB673" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
					            mb={4}
					            
					            type='submit'
			          		>
			           		Send Message
			          		</Button>

				</Form>

				          </Box>
				         </HStack>
				 </div>
			</Box>
		</>
		)
}

export default SendMessage