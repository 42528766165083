import React ,{useEffect,useState} from 'react';
import * as Yup from 'yup';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react'
import {InputText,Form,SelectFieldSearch,SelectField,MultiSelectField} from '../../../components/form'
import { toast } from "react-toastify";
import { fetchWrapper } from '../../../helpers';
import { BsFillArrowLeftCircleFill } from "react-icons/bs";

function Edit ({meCloseEdit,loadList,rs,getPage}){
	const [customerList,setCustomerList]= useState([])


	const [formData, setFormData] = useState({
    account_name: rs.account_name,
    customer_id: rs.customer_id,  
    business_name:rs.business_name,
    token:rs.token,
    business_number:rs.business_number,
    status_id:rs.status_id,
    id:rs.id
    
  });

  const FormSchema = Yup.object().shape({
	   account_name: Yup.string().required('Required'),
	   customer_id: Yup.string().required('Required'),
	   business_name: Yup.string().required('Required'),
	   business_number:Yup.string().required('Required'),
	   token: Yup.string().required('Required'),
	 	 
	});

		useEffect(()=>{
		loadCustomerList()
	},[])


		const loadCustomerList = async()=>{
			const url = `${process.env.REACT_APP_API_URL}/customer/list`;
	    const response  = await fetchWrapper.get(url)
	    const customerList = response.data;

		  const allCustomersEntry = { id: 0, name: "All Customers" };
		  
		  
		  const updatedData = [allCustomersEntry, ...customerList];
			const db = { data: updatedData };
	    setCustomerList(db);
		}

  

	

  




	const statusList = {
  data: [
    { id: '1', name: 'Active' },
    { id: '0', name: 'InActive' }
  ]
};


const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		   console.log(data)
		  
	       
	    const url = `${process.env.REACT_APP_API_URL}/whatsapp_api/update`;
  		await fetchWrapper.post(url,data)
 			
  		loadList()
  		meCloseEdit();
  		toast(`Record has been update `, {
	       type: "info"
	    });		
  			
	}

	return(
		<>
				<div className="setting_detail_section">
				<HStack>
						<Box>
							<BsFillArrowLeftCircleFill style={{ fontSize: '24px', cursor: 'pointer' }} onClick={()=> getPage('list')}/>
						</Box>
						<Box>
							<h2 className="main_heading">WhatsApp Account</h2>
						</Box>
				</HStack>
				<Form
		            enableReinitialize
		            validationSchema={FormSchema}
		            initialValues={formData}
		            onSubmit={onSubmit}
				>

			
						<InputText name="account_name" title="Account Name" />
						<SelectField name="customer_id" title="Customers" list={customerList} />

						<InputText name="business_name" title="Business Name" />
						<InputText name="business_number" title="Business Number" />
						<InputText name="token" title="Token" />
						<Box w="30%">
						<SelectField name="status_id" title="Status" list={statusList} />
						</Box>
				 					 
				 	
				 	
				 	
			         <Button py="10px" px="25px" color="#fff" bg="#2BB673" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
					            mb={4}
					            
					            type='submit'
			          		>
			           		 Add WhatsApp Account
			          		</Button>
				</Form>
			</div>	

		</>
	)
}

export default Edit;