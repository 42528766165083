import React,{useEffect,useState} from 'react';
import { BsFillCheckCircleFill } from "react-icons/bs";
import List from './whatsapp_api/List'
import Create from './whatsapp_api/Create'

function Whatsapp(){
	const [viewPage, setViewPage] = useState('');

	useEffect(()=>{
		getPage('list')
	},[])

	const getPage = (pageNumber)=>{
		if(pageNumber === 'list'){
			setViewPage(<List getPage={getPage}/>)
		}
		if(pageNumber === 'create'){
			setViewPage(<Create getPage={getPage}/>)
		}
	}

	return(
		<>
			{viewPage}			
		</>
	);
}

export default Whatsapp;