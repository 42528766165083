import React from 'react';
import { Box, ButtonGroup, Radio } from "@chakra-ui/react";
import {
  
  RadioGroupControl,
  
} from "formik-chakra-ui";

function RadioInput(props) {
    const {name,type,inputValue,title,placeholder,list} = props
    const [value, setValue] = React.useState('')
    const handleChange = (event) => setValue(event.target.value)
    return(
        <>
            <div className="field_section_radio">
                
                 <RadioGroupControl  name={name} label={title}>
                  {list?.map((item) => {
                            return (
                                        <Radio value={item.id}>{item.name}</Radio>
                                )

                  })}
    
          </RadioGroupControl>
                
            </div>
            
        </>
    );
}

export default RadioInput;