import React,{useState,useEffect} from 'react';
import Header from '../components/Header'
import moment from 'moment'
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Heading,
  MenuDivider,
  Tabs, TabList, TabPanels, Tab, TabPanel
} from '@chakra-ui/react'
import { Link,useLocation,useNavigation ,useParams } from "react-router-dom";
import { fetchWrapper } from '../helpers';
import customer_edit_icon from '../images/customer_edit_icon.png'
import customer_add_icon from '../images/customer_add_icon.png'
import CustomModalLink from '../components/CustomModalLink'
import { ChevronDownIcon } from '@chakra-ui/icons'
import DailyMessageChart from './chart/daily-msg.chart';

import CountryWiseChart from './chart/country-wise-msg.chart';

import CountryWiseCost from './chart/country-wise-cost.chart';
import Create from './customer-user/Create'

import ChangePass from './customer-user/ChangePassword';
import UserEdit from './customer-user/Edit';


import Edit from './customer/Edit'
import AddBalance from './customer/AddBalance'
import EditRate from './customer/EditRate'


const CustomerDetail = ()=>{
	const [detail, setDetail]= useState({})
	const [listTrans, setListTrans]= useState([])
	const [listLog, setListLog]= useState([])
	const [listUser, setListUser]= useState([])

	const [dailymsg, setDailymsg]= useState([])
	const [countrymsg, setCountrymsg]= useState([])
	const [countryCost, setCountryCost]= useState([])
	const [activeTab, setActiveTab] = useState(0);
	const {state} = useLocation();
	const { id: paramId } = useParams();
	const id = state?.id || paramId;


	const [loading , setloading] = useState(false);

	const [modalHeader,setmodelHeader] = useState('');
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
	const [isOpenEdit,setIsOpenEdit] = useState(false)   
	const [modalEditView,setModalEditView] = useState(false)
	

	useEffect(()=>{
		loadDetail();
		loadTrans();
		loadLog();
		loadChart();
		LoadUser();

	},[])
	const loadDetail = async() =>{
		setloading(true)
		const url = `${process.env.REACT_APP_API_URL}/customer/by-id?id=${id}`;
      	const data  = await fetchWrapper.get(url)
     	 setDetail(data.data)
     	 setloading(false)
     
	}
	const loadTrans = async() =>{
		setloading(true)
		const url = `${process.env.REACT_APP_API_URL}/transaction/list?customer_id=${id}`;
      	const data  = await fetchWrapper.get(url)
     	 setListTrans(data)
     	 setloading(false)
	}

	const loadLog = async() =>{
		setloading(true)
		const url = `${process.env.REACT_APP_API_URL}/msg-log/list?customer_id=${id}`;
      	const data  = await fetchWrapper.get(url)
     	 setListLog(data)
     	 setloading(false)
	}


	const loadChart = async() =>{
		setloading(true)
		const url = `${process.env.REACT_APP_API_URL}/msg-log-chart?customer_id=${id}`;
      	const data  = await fetchWrapper.get(url)

     	 setDailymsg(data.Dailymsg)
     	 setCountrymsg(data.CountryWiseCount)
     	 setCountryCost(data.CountryWiseCost)
     	 setloading(false)

	}


	const LoadUser = async()=>{
		setloading(true)
		const url = `${process.env.REACT_APP_API_URL}/user/list?customer_id=${id}`;
    const data  = await fetchWrapper.get(url)
    setListUser(data.data);
    setloading(false)
	}


	const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const meAddUser = () => {
		setIsOpenEdit(true);
		setmodelHeader('Create User')
		setModalEditView(<Create  meCloseEdit={meCloseEdit} customer_id={id}  loadList={LoadUser} />)		
	}

	const meChangePass = (rs)=>{
		setIsOpenEdit(true);
		setmodelHeader('Change Password')
		setModalEditView(<ChangePass  meCloseEdit={meCloseEdit} rs={rs}  loadList={LoadUser} />)		
	}


	const changeStatus = async(type,rs)=>{
		// console.log(rs)
		if(type === "status"){
			const data = {
				status_id : rs.status_id ? 0 : 1,
				id:rs.id
			}
			const url = `${process.env.REACT_APP_API_URL}/user/update`;
			await fetchWrapper.post(url,data)
			LoadUser()
		}

		if(type === "edit"){
			setIsOpenEdit(true);
			setmodelHeader('User Edit')
			setModalEditView(<UserEdit  meCloseEdit={meCloseEdit} rs={rs}  loadList={LoadUser} />)		
		}
		
	}


	const meCloseEdit = () => {
  		
		   setIsOpenEdit(false);
		   
	}
	const roundMe = (num) => {

		if(num){
			return num.toFixed(4)
		}
		
	}


	const meEdit = (type)=>{
		if(type === "server"){
			setIsOpenEdit(true);
			setmodelHeader('Edit Customer')
			setModalEditView(<Edit  meCloseEdit={meCloseEdit} rs={detail}  loadList={loadDetail} />)		
		}

		if(type === "add"){
			setIsOpenEdit(true);
			setmodelHeader('Add Balance')
			setModalEditView(<AddBalance  meCloseEdit={meCloseEdit} rs={detail}  loadList={loadTrans} />)		
		}

		if(type === "sms"){
			setIsOpenEdit(true);
			setmodelHeader('Add Balance')
			setModalEditView(<EditRate  meCloseEdit={meCloseEdit} rs={detail}  loadList={loadDetail} />)		
		}
	}


	return (
		<>
		{loading &&
        <HStack width="100%" h="100vh" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
          <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='blue.500'
          size='xl'
        />
      </HStack>
    }
		<CustomModalLink
          showModalButtonText={modalHeader}
          modalHeader={modalHeader}
          isOpenEdit={isOpenEdit}
          onCloseEdit={meCloseEdit}
          onOpenEdit={onOpenEdit}
          
          modalBody={modalEditView}
    />
			<Header/>

			<Box className="body_section">

				<HStack justifyContent="space-between" mb="25px">
          <Box>
            <Text fontSize="32px" fontWeight="300" color="#000000" my="0px" fontFamily="'Inter', sans-serif">Customers</Text>
          </Box>
          	
        </HStack>

				<HStack className="customer_main_data_section">
					<Box className="customer_box">
						<Heading>{detail.business_name}</Heading>
						<Text>Business Name</Text>
						
					</Box>
					<Box className="customer_box">
						<Heading>{detail.name}</Heading>
						<Text>Contact Person</Text>
						
					</Box>
					<Box className="customer_box">
						<Heading>{detail.contact_no}</Heading>
						<Text>Contact No</Text>
						
					</Box>

					<Box className="customer_box">
						<Heading>{detail?.CustomerType?.name}</Heading>
					<Text>Business Type</Text>
						
					</Box>
					<Box className="customer_box">
						<Heading>{detail?.Account?.name}</Heading>
						<Text>Account Type</Text>
						
					</Box>
					<Box className="customer_box">
						<Heading>{`€${detail.credit_limit}`}</Heading>
						<Text>Credit Limit</Text>
						
					</Box>
					<Box className="customer_box">
						<Heading>{detail.server_ip}</Heading>
						<HStack justifyContent="space-between">
							<Box>
								<Text>Server IP</Text>
							</Box>
							<Box onClick={()=> meEdit('server')}>
								<Link>
									<Image src={customer_edit_icon} />
								</Link>
							</Box>
						</HStack>
					</Box>
					<Box className="customer_box">
						<Heading>{roundMe(listTrans?.total)}</Heading>
						
						<HStack justifyContent="space-between">
							<Box>
								<Text>Balance</Text>
							</Box>
							<Box onClick={()=> meEdit('add')}>
								<Link>
									<Image src={customer_add_icon} />
								</Link>
							</Box>
						</HStack>
					</Box>
					<Box className="customer_box">
						<Heading>{`SMS: ${detail.sms_rate}%`}</Heading>
						
						<HStack justifyContent="space-between">
							<Box>
								<Text>Rates</Text>
							</Box>
							<Box onClick={()=> meEdit('sms')}>
								<Link>
									<Image src={customer_edit_icon} />
								</Link>
							</Box>
						</HStack>
					</Box>
				</HStack>

				<Tabs className="customer_tabs_section" onChange={handleTabChange}>
				  <TabList className="customer_tabs_head_section">
				    <Tab>Analytics</Tab>
				    <Tab>Transactions</Tab>
				    <Tab>Messages Log</Tab>
				    <Tab>Users</Tab>


				    {(activeTab === 3) && (
		          <Button className="user_add_button" onClick={meAddUser}>
		            Add New User
		          </Button>
		        )}
				  </TabList>

				  <TabPanels>
				    <TabPanel className="analytics_panel_section">
				    	<HStack mb="30px">
								<Box className="analytics_box_section">
									<Heading>€ {roundMe(listTrans?.totalCR)}</Heading>
								
									<Text>Total Top-up <span>(Till Date)</span></Text>
								</Box>

								<Box className="analytics_box_section">
									
									<Heading>€ {roundMe(listTrans?.totalDR)}</Heading>
								
									<Text>Total Used <span>(Till Date)</span></Text>
								</Box>

								<Box className="analytics_box_section">
									<Heading>€ {roundMe(listTrans?.total)}</Heading>
								
									<Text>Current Balance</Text>
								</Box>
							</HStack>

							<HStack mb="30px">
								<Box className="analytics_box_section">
									<Heading>{listLog?.data?.length}</Heading>
								
									<Text>Messages Request</Text>
								</Box>

								<Box className="analytics_box_section">
									<Heading>{listLog?.msgSentCount}</Heading>
								
									<Text>Messages Successfully Delivered</Text>
								</Box>

								<Box className="analytics_box_section">
									<Heading>{listLog?.msgFail}</Heading>
								
									<Text>Messages Failed</Text>
								</Box>
							</HStack>	

							<Box className="chart_box">
								<DailyMessageChart datalist={dailymsg}/>
							</Box>

							<Box className="chart_box">
								<CountryWiseChart datalist={countrymsg}/>
							</Box>

							<Box className="chart_box">
								<CountryWiseCost datalist={countryCost}/>
							</Box>
				      
				    </TabPanel>
				    <TabPanel className="customer_transaction_panel">
				      

				    	<div className="table_section">
          <TableContainer width="100%">
            <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
              <Thead >
                <Tr>
                  <Th>Date</Th>
                  <Th>Time</Th>
                 
                 
                  <Th>Type</Th>
                   <Th>Details</Th>
                  <Th>Amount</Th>
                


                  

                  
                </Tr>
              </Thead>

              <Tbody fontSize="14px">
              {listTrans?.data?.map((rs,index)=>{
              	return(
		          	<>
		          		<Tr> 
	                          
	                           
	                            <Td>{moment(rs.created_at).format('DD MMMM YYYY')}</Td>
	                            <Td>{moment(rs.created_at).format('hh:mm A')}</Td>
	                            <Td style={{ color: rs.trans_type === "CR" ? "green" : "red" , fontWeight:'700', textTransform:'uppercase'}}>
															  {rs.trans_type === "CR" ? "Credit" : "Debit"}
															</Td>
	                            <Td>{rs.trans_desc}</Td>
	                            <Td>{rs.amount}</Td>
	                           
	                      
                            </Tr>
		          	</>
              	)
              })}

	             
                           	
	                    
            
              </Tbody>
             
              
            </Table>
            
          </TableContainer>
        </div>

				    </TabPanel>
				    <TabPanel className="customer_transaction_panel">
				      



<div className="table_section">
          <TableContainer width="100%">
            <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
              <Thead >
                <Tr>
                  <Th>Message ID</Th>
                  <Th>Message Sent To</Th>
                 
                 
                  <Th>Message</Th>
                   <Th>Channel Used</Th>
                  <Th>Cost</Th>

                  <Th>Operator</Th>
                	<Th>Date & Time</Th>

                	<Th>Status</Th>
                  

                  
                </Tr>
              </Thead>

              <Tbody fontSize="14px">
              {listLog?.data?.map((rs,index)=>{
              	return(
		          	<>
		          		<Tr> 
	                          <Td>{rs.message_id}</Td>
	                          <Td>{rs.sent_to}</Td> 
	                          <Td>{rs.message}</Td>
	                          <Td>{rs.MsgChannel?.name}</Td>  
	                          <Td>Sale: {rs.sale}
	                          <br />
	                          buy:{rs.cost}
	                          </Td> 
	                      	 <Td>
                              <Text>{rs?.Operator?.name}</Text>
                              <Text style={{fontSize:'12px',color:"#727272"}}>{rs?.Country?.name}</Text>
                            </Td>
	                      	 <Td>
                              <Text>Received: {moment(rs.receive_date).format('DD/MM/YY | HH:mm')}</Text>
                              <Text>Sent: {moment(rs.sent_date).format('DD/MM/YY | HH:mm')}</Text>
                            </Td>
                            <Td>
                            <div style={{color:rs?.Status?.color}}>{rs?.Status?.msg_status == 0 ? 'Failed' : 'Success'}</div>
                            {rs?.Status?.name}</Td>
                            </Tr>
		          	</>
              	)
              })}

	             
                           	
	                    
            
              </Tbody>
             
              
            </Table>
            
          </TableContainer>
        </div>


				    </TabPanel>

				  <TabPanel className="customer_transaction_panel">
						<div className="table_section">
		          <TableContainer width="100%">
		            <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
		              <Thead >
		                <Tr>
		                  <Th>Full Name</Th>
		                  <Th>User ID</Th>
		                  <Th>Status</Th>
		                  <Th></Th>
			                <Th></Th> 

		                  
		                </Tr>
		              </Thead>

		              <Tbody fontSize="14px">
			              {listUser?.map((rs,index)=>{
			              	return(
					          	<>
					          		<Tr> 
	                          <Td>{rs.username}</Td>
                            <Td>{rs.email}</Td>
                            <Td>{rs.status_id === 1 ? "Active" : "InActive"}</Td>
                            <Td style={{color:'#1F5AF4' ,cursor:'pointer'}} onClick={()=> meChangePass(rs)}>Change Password</Td>
                            <Td style={{color:"#0000EE"}}>
	                            <Menu>
	                              <MenuButton fontSize="14px" >
	                              Action
	                                
	                                  <ChevronDownIcon />
	                              </MenuButton>

	                              <MenuList  style={{minWidth:"20px",border:"1px solid #CCCCCC"}}>
	                                 <MenuItem onClick={()=> changeStatus('edit' , rs)}>Edit</MenuItem>
	                                  <MenuDivider/>
									                 <MenuItem onClick={()=> changeStatus('status' , rs)}>{rs.status_id === 1 ? "Revoke Access" : "Grant Access"}</MenuItem>	                              
	                                
	                              </MenuList>
	                            </Menu>
                         		</Td>

                        </Tr>
					          	</>
			              	)
			              })}
									</Tbody>
		             
		              
		            </Table>
		            
		          </TableContainer>
		        </div>
				  </TabPanel>



				  </TabPanels>
				</Tabs>
			</Box>
		</>
		)
}

export default CustomerDetail