import React ,{useEffect,useState} from 'react';
import * as Yup from 'yup';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react'
import {InputText,Form,SelectFieldSearch,SelectField,MultiSelectField} from '../../components/form'
import { toast } from "react-toastify";
import { fetchWrapper } from '../../helpers';

function Edit ({meCloseEdit,rs,loadList,customer_id}){
	
	

	const [formData, setFormData] = useState({
    username: rs.username,
    email: rs.email,  
    customer_id:customer_id,
    id:rs.id
    
  });

  const FormSchema = Yup.object().shape({
	   username: Yup.string().required('Required'),
	   email: Yup.string().required('Required'),
	   
	 	
	});

  

	

  


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	       
	    const url = `${process.env.REACT_APP_API_URL}/user/update`;
  		await fetchWrapper.post(url,data)
 		loadList()
  		meCloseEdit();
  		toast(`Record has been update `, {
	       type: "info"
	    });		
	}

	return(
		<>
			
				<Form
		            enableReinitialize
		            validationSchema={FormSchema}
		            initialValues={formData}
		            onSubmit={onSubmit}
				>

					<Box mb="10px">
						<InputText name="username" title="Full Name" />
					</Box>
					<Box mb="10px">
						<InputText name="email" title="User ID " />
					</Box>
					
					
				 					 
				 	
				 	
				 	
			         <Button py="10px" px="25px" color="#fff" bg="#2BB673" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
					            mb={4}
					            
					            type='submit'
			          		>
			           		 Update User
			          		</Button>
				</Form>

		</>
	)
}

export default Edit;