import React , {useEffect,useState} from 'react';
import Header from '../components/Header'
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Heading,
  MenuDivider,
  Tabs, TabList, TabPanels, Tab, TabPanel

} from '@chakra-ui/react'

import { 

	ChannelChart,
	MessageDeliverd,
	CustomerType,
	CustomerWiseMessageCount,
	CustomerWiseRenevuCount,
	DailyMsgCount,
	CountryWiseMsg,
	CountryWiseCost

} from './chart/dashboard-chart';

import { fetchWrapper } from '../helpers';

const Dashboard = ()=>{
	const [data , setData] = useState([]);
	const [loading , setloading] = useState(false);


	useEffect(()=>{
		LoadData();

	},[])

	const LoadData = async() =>{
		setloading(true)
		const url = `${process.env.REACT_APP_API_URL}/dashboard`;
      	const data  = await fetchWrapper.get(url)
     	setData(data.data)   
     	setloading(false)
	}
	const roundMe = (num) => {

		if(num){
			return num.toFixed(4)
		}
		
	}
	return (
		<>
		{loading &&
        <HStack width="100%" h="100vh" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
          <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='blue.500'
          size='xl'
        />
      </HStack>
    }
			<Header/>

			<Box className="dashboard_panel_section">
				<Box mb="30px">
	            	<Text fontSize="32px" fontWeight="300" color="#000000" my="0px" fontFamily="'Inter', sans-serif">Dashboard</Text>
	          	</Box>

	          	<Box className="analytics_panel_section">
		          	<HStack mb="30px">
						<Box className="analytics_box_section">
							<Heading>{data?.customerCount}</Heading>
						
							<Text>Total Customers </Text>
						</Box>

						<Box className="analytics_box_section">
							<Heading>{data?.MessageVolumeCount}</Heading>
						
							<Text>Total Message Volume </Text>
						</Box>

						<Box className="analytics_box_section">
							<Heading>€ {roundMe(data?.roundedTotalCR)}</Heading>
						
							<Text>Total Top-up <span>(Till Date)</span></Text>
						</Box>

						<Box className="analytics_box_section">
							
							<Heading>€ {roundMe(data?.roundedTotalDR)}</Heading>
						
							<Text>Total Used <span>(Till Date)</span></Text>
						</Box>

						<Box className="analytics_box_section">
							
							<Heading>€ {roundMe(data?.totalBalance)}</Heading>
						
							<Text>Current Balance</Text>
						</Box>

					</HStack>


					<HStack w="100%">
						<Box w="33%" className="chart_box" >
							<ChannelChart datalist={data}/>
						</Box>
						<Box w="33%" className="chart_box">
							<MessageDeliverd datalist={data}/>
						</Box>
						<Box w="33%" className="chart_box">
							<CustomerType datalist={data}/>
						</Box>
					</HStack>


					<Box  className="chart_box">
						<CustomerWiseMessageCount datalist={data} />
					</Box>

					<Box  className="chart_box">
						<CustomerWiseRenevuCount datalist={data}/>
					</Box>


					<Box  className="chart_box">
						<DailyMsgCount datalist={data}/>
					</Box>

					<Box className="chart_box">
						<CountryWiseMsg datalist={data}/>
					</Box>

					<Box className="chart_box">
						<CountryWiseCost datalist={data}/>
					</Box>

				</Box>

				

			</Box>
		</>
		)
}

export default Dashboard