import "prismjs/themes/prism-tomorrow.css";
import React ,{useEffect,useState} from 'react';
import * as Yup from 'yup';
import Prism from 'prismjs';
import "prismjs/components/prism-json";
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react'
import {InputText,Form,SelectFieldSearch,SelectField,MultiSelectField} from '../../../components/form'
import { toast } from "react-toastify";
import { fetchWrapper } from '../../../helpers';
import { BsFillArrowLeftCircleFill ,BsTrashFill } from "react-icons/bs";

function Edit ({meCloseEdit,loadList,rs,getPage}){
	const [customerList,setCustomerList]= useState([])
	
	

const [initialValues, setInitialValues] = useState(null);
	const headerArray = JSON.parse(rs.header)
	//const result = []
	let headerObj = {}
	const resultHeader = Object.keys(headerArray).map((mkey) => {
				headerObj[mkey] = headerArray[mkey]
				return {key:mkey,value:headerArray[mkey]}
	});

	const [header,setHeader]= useState(headerObj);


	let payloadObj = {}
	const payLoadArray = JSON.parse(rs.payload)
	const resultPayload = Object.keys(payLoadArray).map((mkey) => {
				payloadObj[mkey] = payLoadArray[mkey]
				return {key:mkey,value:payLoadArray[mkey]}
	});
	const [payload,setPayload]= useState(payloadObj);
	const [formData, setFormData] = useState({
    account_name: rs.account_name,
    customer_id: rs.customer_id,  
    method:rs.method,
    url:rs.url,
    headers: resultHeader,
    payloads: resultPayload,
    status_id:rs.status_id,
    id:rs.id
    
    
  });

  const FormSchema = Yup.object().shape({
	   account_name: Yup.string().required('Required'),
	   customer_id: Yup.string().required('Required'),
	   url:Yup.string().required('Required'),
	   method:Yup.string().required('Required'),
	 	
	});

		useEffect(()=>{
		loadCustomerList()

	},[])
		 useEffect(() => {
    if (!initialValues || formData.headers.length > 0) {
        setInitialValues({ ...formData });
    }
    Prism.highlightAll();
}, []);

		  useEffect(() => {
    Prism.highlightAll(); 
  }, [payload]);


		const loadCustomerList = async()=>{
			const url = `${process.env.REACT_APP_API_URL}/customer/list`;
	    const response  = await fetchWrapper.get(url)
	    const customerList = response.data;

		  const allCustomersEntry = { id: 0, name: "All Customers" };
		  
		  
		  const updatedData = [allCustomersEntry, ...customerList];
			const db = { data: updatedData };
	    setCustomerList(db);
		}

  

	

  


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = {
		   	...values,
		   }
		   data.header = header;
		   data.payload = payload;

		  
	       
	    const url = `${process.env.REACT_APP_API_URL}/sms_api/update`;
  		await fetchWrapper.post(url,data)
 			loadList()
  		meCloseEdit();
  		toast(`Record has been update `, {
	       type: "info"
	    });		
  	
	}

	const methodList = {
  data: [
    { id: 'Post', name: 'Post' },
    { id: 'Get', name: 'Get' }
  ]
};



	const addHeader = () => {
    const newheaders = {
       key: '',
      value: '',
    };


    const updatedFormData = {
        ...formData,
        headers: [...formData.headers, newheaders]
    };
    setFormData(updatedFormData);
  };


  const addPayload = ()=>{
  	const newPayload = {
       key: '',
      value: '',
    };


    const updatedFormData = {
        ...formData,
        payloads: [...formData.payloads, newPayload]
    };
    setFormData(updatedFormData);
  }

    const removeHeader = (index) => {
        const updatedHeadersData = [...formData.headers];
        updatedHeadersData.splice(index, 1);

        const updatedFormData = {
            ...formData,
            headers: updatedHeadersData
        };

        setFormData(updatedFormData);
    };

    const removePayload = (index) => {
    	const messageObject = {};
        const updatedPayloadData = [...formData.payloads];
        updatedPayloadData.splice(index, 1);

        const updatedFormData = {
            ...formData,
            payloads: updatedPayloadData
        };

        updatedPayloadData.forEach(payloads => {
	        messageObject[payloads.key] = payloads.value; 
	    });
        setPayload(messageObject)

        setFormData(updatedFormData);
    };



    const handleHeaderChange = (index, field, value) => {
    	const messageObject = {};
	    const updatedHeaders = formData.headers.map((header, i) => {
	      if (i === index) {
	        return { ...header, [field]: value };
	      }
	      return header;
	    });

    	updatedHeaders.forEach(header => {
	        messageObject[header.key] = header.value; 
	    });

	    setHeader(messageObject)

	    setFormData(prevState => ({
	      ...prevState,
	      headers: updatedHeaders
	    }));
	  };


const handlePayloadChange = (index, field, value) => {
    
    const messageObject = {};
    const updatedPayloadData = formData.payloads.map((payload, i) => {
        if (i === index) {
            return { ...payload, [field]: value }; 
        }
        return payload; 
    });

    
    updatedPayloadData.forEach(payload => {
        messageObject[payload.key] = payload.value; 
    });

    Prism.highlightAll();
    setPayload(messageObject);
    

    
    setFormData(prevState => ({
        ...prevState,
        payloads: updatedPayloadData
    }));
};

	const statusList = {
	  data: [
	    { id: '1', name: 'Active' },
	    { id: '0', name: 'InActive' }
	  ]
	};

	return(
		<>
				<div className="setting_detail_section">
				<HStack>
						<Box>
							<BsFillArrowLeftCircleFill style={{ fontSize: '24px', cursor: 'pointer' }} onClick={()=> getPage('list')}/>
						</Box>
						<Box>
							<h2 className="main_heading">SMS API Account</h2>
						</Box>
				</HStack>
				<Form
		            enableReinitialize
		            validationSchema={FormSchema}
		            initialValues={initialValues}
		            onSubmit={onSubmit}
				>

			
						<InputText name="account_name" title="Account Name" />
						<SelectField name="customer_id" title="Customers" list={customerList} />

						<HStack alignItems="end" w="100%">
							<Box  w="15%">
									<SelectField name="method" title="API Endpoint" list={methodList} />
							</Box>
							<Box w="85%">
								<InputText name="url" title="" placeholder="API Endpoint" />
							</Box>
						</HStack>
							
							<Box mb="10px">
								<Text fontSize="14px" mb="0px">Api Header</Text>
											{formData.headers.map((head, index) => (
	                        <HStack key={index} w="100%" mt="20px" alignItems="baseline">
	                            <Box  w="30%">
																		<InputText name={`headers[${index}].key`} title="" placeholder="key" onChange={(e) => handleHeaderChange(index, 'key', e)}/>
															</Box>
															<Box w="60%">
																	<InputText name={`headers[${index}].value`} title="" placeholder="value"   onChange={(e) => handleHeaderChange(index, 'value', e)} />
															</Box>


	                             

			           						 {index !== 0 ? 
	                              <Box w="10%" onClick={() =>removeHeader(index)}>
																	<BsTrashFill style={{cursor:'pointer'}}/>
															</Box>
	                         		: ""} 
	                        </HStack>
	                    ))}
								<Button onClick={addHeader}
								  bg="#FFFFFF" 
								  fontSize="12px" 
								  borderColor="#CCCCCC" 
								  borderWidth="1px"
								  px="30px"
								>Add</Button>
							</Box>



							<Box mb="20px">
								<Text fontSize="14px" mb="0px">API Payload Variables</Text>
											{formData.payloads.map((head, index) => (
	                        <HStack key={index} w="100%" mt="20px" alignItems="baseline">
	                            <Box  w="30%">
																		<InputText name={`payloads[${index}].key`} title="" placeholder="key" onChange={(e) => handlePayloadChange(index, 'key', e)}/>
															</Box>
															<Box w="60%">
																	<InputText name={`payloads[${index}].value`} title="" placeholder="value"   onChange={(e) => handlePayloadChange(index, 'value', e)} />
															</Box>


	                             

			           						 {index !== 0 ? 
	                              <Box w="10%" onClick={() =>removePayload(index)}>
																	<BsTrashFill style={{cursor:'pointer'}}/>
															</Box>
	                         		: ""} 
	                        </HStack>
	                    ))}
								<Button onClick={addPayload}
								bg="#FFFFFF" 
								  fontSize="12px" 
								  borderColor="#CCCCCC" 
								  borderWidth="1px"
								  px="30px"

								>Add</Button>
							</Box>


							<pre style={{ backgroundColor: 'black', textShadow: 'none', color: 'white', padding: '0px', borderRadius: '8px' }}>
	              <span style={{padding: 15,backgroundColor: '#2F2F2F',fontSize: '14px', display: 'block', marginBottom: '10px' }}>json</span>
	              <code className="language-json" style={{padding: '0px 15px 15px',display: 'block' }}>
	                {JSON.stringify(payload, null, 2)}
	              </code>
	            </pre>			 

						<Box w="30%">
							<SelectField name="status_id" title="Status" list={statusList} />
						</Box>
				 	
				 	
				 			<HStack alignItems="baseline" w="100%" mt="10px">
								<Box>
										<Button py="10px" px="25px" color="#fff" bg="#2BB673" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
					            mb={4}

					            
					            type='submit'
			          		>
			           		Add API Accout
			          		</Button>
								</Box>
								<Box>
									<Button py="10px" px="25px" color="#000000" bg="#FFFFFF" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
					            mb={4}
					              borderColor="#CCCCCC" 
								  			borderWidth="1px"
								  			onClick={()=> getPage('list')}
			          		>
			       				Close
			          		</Button>
								</Box>
							
							</HStack>

				</Form>
			</div>	

		</>
	)
}

export default Edit;