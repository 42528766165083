import React from 'react';
import Header from '../components/Header'
import { HStack, Box, Image ,Text} from '@chakra-ui/react';
import List from './customer/List'

const Dashboard = ()=>{
	return (
		<>
			<Header/>

			<Box>
				<List/>
			</Box>
		</>
		)
}

export default Dashboard