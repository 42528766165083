import React from 'react';
import {
  
  InputControl,
  
} from "formik-chakra-ui";

function InputText(props) {
	const {name,type,inputValue,title,placeholder,onChange} = props
	const [value, setValue] = React.useState('')
	const handleChange = (event) => {
		// console.log(event)
    const selectedValue = event.target.value;
    if(onChange){
    	onChange(selectedValue);	
    }
    
  };
	return(
		<>
			<div className="field_section">
				
				 <InputControl inputProps={{...props.inputProps,placeholder}} name={name} label={title} onChange={handleChange} />
				
			</div>
			
		</>
	);
}

export default InputText;