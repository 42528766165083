import React,{useState} from 'react';
import { Routes, Route, Link, Outlet,useLocation } from 'react-router-dom';
import Header from '../components/Header'
import { BsArrowLeftSquare, BsShop } from "react-icons/bs";



const Setting = () => {
 	const location = useLocation();
    const [activeItem, setActiveItem] = useState(location.pathname);

    const handleItemClick = (url) => {
        setActiveItem(url);
    };

	return(
		<>
			<Header />

			<div className="body_section">
				<div className="container">
					<h1 className="page_main_heading">Settings</h1>

					<div className="setting_page">
						<div className="setting_menu_section">
							<div className="menu_section">
								<ul>
									<li className={activeItem === "/settings" ? "active" : ""}>
										<a href="/settings" onClick={() => handleItemClick("/settings")}>
											
											<p>Countries</p>
										</a>
									</li>

									<li className={activeItem === "/settings/operator" ? "active" : ""}>
										<a href="/settings/operator" onClick={() => handleItemClick("/settings/operator")}>
											
											<p>Operator</p>
										</a>
									</li>

									<li className={activeItem === "/settings/status" ? "active" : ""}>
										<a href="/settings/status" onClick={() => handleItemClick("/settings/status")}>
											
											<p>Message Status</p>
										</a>
									</li>

									<li className={activeItem === "/settings/sender" ? "active" : ""}>
										<a href="/settings/sender" onClick={() => handleItemClick("/settings/sender")}>
											
											<p>Sender ID</p>
										</a>
									</li>

									<li className={activeItem === "/settings/sms" ? "active" : ""}>
										<a href="/settings/sms" onClick={() => handleItemClick("/settings/sms")}>
											
											<p>SMS API Accounts</p>
										</a>
									</li>

									<li className={activeItem === "/settings/whatsapp" ? "active" : ""}>
										<a href="/settings/whatsapp" onClick={() => handleItemClick("/settings/whatsapp")}>
											
											<p>WhatsApp Accounts</p>
										</a>
									</li>


									<li className={activeItem === "/settings/user" ? "active" : ""}>
										<a href="/settings/user" onClick={() => handleItemClick("/settings/user")}>
											
											<p>Users</p>
										</a>
									</li>

									
								</ul>
							</div>		
						</div>

						<Outlet />
					</div>
				</div>
				
			</div>
			
		</>
	);
}

export default Setting;