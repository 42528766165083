import React from 'react'
import './App.css';
import Login from './pages/auth/Login';
import Dashboard from './pages/Dashboard';
import Customer from './pages/Customer';
import CustomerDetail from './pages/CustomerDetail';
import Price from './pages/Price';
import Message from './pages/Message';
import GetStarted from './pages/GetStarted';
import Contact from './pages/Contact';
import WebHook from './pages/WebHook';
import SendMessage from './pages/SendMessage';
import { PrivateRoute } from './components';


import Setting from './pages/Setting';
import Country from './pages/setting/Country';
import Operator from './pages/setting/Operator';
import User from './pages/setting/User';
import Status from './pages/setting/Status';
import Sender from './pages/setting/Sender';
import Sms from './pages/setting/Sms';
import Whatsapp from './pages/setting/Whatsapp';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ChakraProvider } from '@chakra-ui/react';

function App() {
  return (
     <>
    <ChakraProvider>
     
        <Routes>            
          <Route path="/" element={<Login />} />


          <Route path="/dashboard" element={
            <PrivateRoute>
              <Dashboard/>
            </PrivateRoute>
          } />


          <Route path="/customer" element={
                    <PrivateRoute>
                        <Customer />
                        
                    </PrivateRoute>} />

                    <Route path="/customer-detail/:id" element={
                    <PrivateRoute>
                        <CustomerDetail />
                        
                    </PrivateRoute>} />

          <Route path="/price" element={
            <PrivateRoute>
                <Price />
                
            </PrivateRoute>
          } />

           <Route path="/web-hook" element={
            <PrivateRoute>
                <WebHook />
                
            </PrivateRoute>
          } />

          <Route path="/message" element={
            <PrivateRoute>
                <Message />
                
            </PrivateRoute>
          } />

          <Route path="/get-started" element={
            <PrivateRoute>
                <GetStarted />
                
            </PrivateRoute>
          } />

            <Route path="/contact" element={
            <PrivateRoute>
                <Contact />
                
            </PrivateRoute>
          } />


          <Route path="/send-message" element={
            <PrivateRoute>
                <SendMessage />
                
            </PrivateRoute>
          } />

          <Route path="/settings" element={
            <PrivateRoute>
                <Setting />
                
            </PrivateRoute>
          } >


            <Route
                path="/settings"
                element={
                    <PrivateRoute>
                        <Country />
                        
                    </PrivateRoute>
                }
            />

            <Route
                path="/settings/operator"
                element={
                    <PrivateRoute>
                        <Operator />
                        
                    </PrivateRoute>
                }
            />

             <Route
                path="/settings/status"
                element={
                    <PrivateRoute>
                        <Status />
                        
                    </PrivateRoute>
                }
            />

            <Route
                path="/settings/sender"
                element={
                    <PrivateRoute>
                        <Sender />
                        
                    </PrivateRoute>
                }
            />
             <Route
                path="/settings/user"
                element={
                    <PrivateRoute>
                        <User />
                        
                    </PrivateRoute>
                }
            />
             <Route
                path="/settings/sms"
                element={
                    <PrivateRoute>
                        <Sms />
                        
                    </PrivateRoute>
                }
            />
            <Route
                path="/settings/whatsapp"
                element={
                    <PrivateRoute>
                        <Whatsapp />
                        
                    </PrivateRoute>
                }
            />
          </Route>
         
                 
          </Routes>
        
    </ChakraProvider>
   </>
  );
}

export default App;
