import React ,{useState,useEffect} from 'react';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import CustomModal from '../../../components/CustomModal'
import CustomModalLink from '../../../components/CustomModalLink'
import Create from './Create';
import Edit from './Edit';

import { fetchWrapper } from '../../../helpers';

function List({getPage}) {
	const [loading,setLoading] = useState(false);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
	const [isOpenEdit,setIsOpenEdit] = useState(false)   
	const [modalEditView,setModalEditView] = useState(false)
	const [list,setList] = useState([])

	const [modalHeader,setmodelHeader] = useState('');

	const meClose = () => {
		
	   onClose();	   
	}

	useEffect(()=>{
		loadList();
	},[])

	const loadList = async()=>{
		setLoading(true);
		const url = `${process.env.REACT_APP_API_URL}/sms_api/list-all`;
    const data  = await fetchWrapper.get(url)
    setList(data.data);
    setLoading(false);
	}

	const meEdit = (rs) => {
		setIsOpenEdit(true);
		setmodelHeader('Update SMS API')
		setModalEditView(<Edit  meCloseEdit={meCloseEdit} rs={rs}  loadList={loadList} />)		
	}



	const meCloseEdit = () => {
  		
		   setIsOpenEdit(false);
		   
	}



	return(
		<>
		{loading &&
          <HStack width="100%" h="100vh" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
            <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          />
        </HStack>
      }
		 <CustomModalLink
          showModalButtonText={modalHeader}
          modalHeader={modalHeader}
          isOpenEdit={isOpenEdit}
          onCloseEdit={meCloseEdit}
          onOpenEdit={onOpenEdit}
          
          modalBody={modalEditView}
    />
			<div className="setting_detail_section">

				<HStack justifyContent="space-between">
						<Box>
							<h2 className="main_heading">SMS API Account</h2>
						</Box>
						<Box>
							<HStack>
									
									<Box>
										<Button onClick={()=> getPage('create')} className="mybutton">Add New SMS API Account</Button>
									
									</Box>
							</HStack>
						</Box>
				</HStack>



				<div className="table_section">
		          <TableContainer width="100%">
		            <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
		              <Thead >
		                <Tr>
		               	 
		                  <Th>Account Name</Th>
		                  <Th>Method</Th>
		                  <Th>End Point </Th>
		                 
		                 
		                  <Th>Authentication Method</Th>
		    							<Th>Status</Th>
											<Th></Th>

		                  

		                  
		                </Tr>
		              </Thead>

		              <Tbody fontSize="14px">
		              			{list.map((rs,index)=>{
		              				return(
		              					<>
		              						<Tr> 	
					                            <Td>{rs.account_name}</Td>
					                            <Td>{rs.method}</Td>
					                            <Td>{rs.url}</Td>
					                            <Td></Td>
					                            <Td>{rs.status_id === 1 ? "Active" : "InActive"}</Td>
					                           
					                            

					                            
					                            
					                          	<Td style={{color:"#0000EE"}}>
						                            <Menu>
						                              <MenuButton fontSize="14px" >
						                              Action
						                                
						                                  <ChevronDownIcon />
						                              </MenuButton>

						                              <MenuList  style={{minWidth:"20px",border:"1px solid #CCCCCC"}}>
						                                <MenuItem  h="16px" fontSize="12px" onClick={()=> meEdit(rs)} >Edit</MenuItem>						                                
						                              </MenuList>
						                            </Menu>
				                           		</Td>
					                      
				                            </Tr>
		              					</>
		              				)
		              			})}
				          		
				         

			             
		                           	
			                    
		            
		              </Tbody>
		             
		              
		            </Table>
		            
		          </TableContainer>
		        </div>
				
			</div>
		</>
	)
}


export default List
