import React ,{useEffect,useState} from 'react';
import * as Yup from 'yup';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
  Spinner,  
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react'
import {InputText,Form,SelectFieldSearch,SelectField,MultiSelectField,RadioInput} from '../../components/form'
import { toast } from "react-toastify";
import { fetchWrapper } from '../../helpers';




function Create ({meCloseEdit,loadList,rs}){
	const [TypeList,SetTypeList] = useState([]);
	const [Volumn,SetVolumnList] = useState([]);
	const [accountList,setAccountList] = useState([]);
	const [value,setValue] = useState(`${rs.account_id}`);


	const [smsList,SetSmsList] = useState([]);
	const [WhatsappList,SetWhatsappList] = useState([]);


	const [formData, setFormData] = useState({
    name: rs.name,
    contact_no: rs.contact_no,
    business_name: rs.business_name,
    customer_type_id: rs.customer_type_id,
    volumn_id: rs.volumn_id,
    server_ip: rs.server_ip,
    account_id:rs.account_id,
   	sms_api_id:rs.sms_api_id,
   	whatsapp_api_id:rs.whatsapp_api_id,
    credit_limit:rs.credit_limit,  
    auto_send:`${rs.auto_send}`,
    id:rs.id
    
    
    
  });

  const FormSchema = Yup.object().shape({
	   name: Yup.string().required('Required'),
	   contact_no: Yup.string().required('Required'),
	   business_name: Yup.string().required('Required'),
  	customer_type_id: Yup.string().required('Required'),
   	volumn_id: Yup.string().required('Required'),
   	server_ip: Yup.string().required('Required'),
   	account_id: Yup.string().required('Required'),
   	sms_api_id: Yup.string().required('Required'),
   	whatsapp_api_id: Yup.string().required('Required'),
	 	
	});

  

	useEffect(() => {
        LoadTypeList();
        LoadVolumnList();
        LoadAccountList();
        LoadSmsList()
        LoadWhatsappList()
       
        
    }, [value]);

  	const LoadTypeList = async () => {
	      const url = `${process.env.REACT_APP_API_URL}/customer-type/list`;
	      const data  = await fetchWrapper.get(url)
	      // console.log(data)
	      SetTypeList(data)
  	}

  	const LoadVolumnList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/volumn/list`;
      const data  = await fetchWrapper.get(url)
      // console.log(data)
      SetVolumnList(data)
  	}

  	const LoadAccountList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/account/list`;
      const data  = await fetchWrapper.get(url)
      // console.log(data)
      setAccountList(data)
  	}

  	const LoadSmsList = async () => {
	      const url = `${process.env.REACT_APP_API_URL}/sms_api/list?customer_id=${rs.id}`;
	      const data  = await fetchWrapper.get(url)
	      // console.log(data)
	      const  Items = data.data.map(item => {
				  let obj = {};

				  
				  Object.keys(item).filter(key => {
				    switch (key) {
				      case 'account_name':
				        obj['name'] = item['account_name']
				        break;	     
				      default:
				        obj[key] = item[key]
				    }
				  })

				  return obj;
				})
	      const smsList = { data : Items}
	      SetSmsList(smsList)
  	}

		const LoadWhatsappList = async () => {
	      const url = `${process.env.REACT_APP_API_URL}/whatsapp_api/list?customer_id=${rs.id}`;
	      const data  = await fetchWrapper.get(url)
	      // console.log(data)
	     const  Items = data.data.map(item => {
				  let obj = {};

				  
				  Object.keys(item).filter(key => {
				    switch (key) {
				      case 'account_name':
				        obj['name'] = item['account_name']
				        break;	     
				      default:
				        obj[key] = item[key]
				    }
				  })

				  return obj;
				})
	      const whtspList = { data : Items}
	      SetWhatsappList(whtspList)
  	}
  


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	       
	    const url = `${process.env.REACT_APP_API_URL}/customer/update`;
  		await fetchWrapper.post(url,data)
 		loadList()
  		meCloseEdit();
  		toast(`Record has been update `, {
	       type: "info"
	    });		
	}

	const handleChange =(e)=>{
  		setValue(e)
  	}


	const priceList = {
		data:[{id : 1 , name:'Standard'}]
	}

	const autoSendList = [
		{id : '1' , name:'Yes'},
		{id : '0' , name:'No'},
	]


	return(
		<>
			
				<Form
		            enableReinitialize
		            validationSchema={FormSchema}
		            initialValues={formData}
		            onSubmit={onSubmit}
				>

					<Box mb="10px">
						<InputText name="name" title="Customer Name" placeholder="Please Enter Customer Name"  />
					</Box>
					<Box mb="10px">
						<InputText name="contact_no" title="Contact No" placeholder="Please Enter Contact Number" />
					</Box>
					<Box mb="10px">
						<InputText name="business_name" title="Business Name" placeholder="Please Enter Business Name"  />
					</Box>

					<HStack w="100%" mb="30px">
						<Box w="30%">
							<SelectField name="account_id" list={accountList} title="Account Type" onChange={handleChange}/>		
						</Box>
						{value === '1' ? null : (
						<>
						<Box w="70%">
							<InputText name="credit_limit" title="Credit Limit  (€)" placeholder="Credit Limit" />
						</Box>
						</>
						)}
					</HStack>

					<HStack w="100%" mb="30px">
						<Box w="30%">
							<SelectField name="customer_type_id" list={TypeList} title="Type"/>		
						</Box>
						<Box w="70%">
							<SelectField name="volumn_id" list={Volumn} title="Expected Monthly Messages Volume"/>
						</Box>
					</HStack>

					<HStack w="100%" mb="10px">
						<Box w="50%">
							<SelectField name="sms_api_id" list={smsList} title="SMS API Account"/>		
						</Box>
						<Box w="50%">
							<SelectField name="whatsapp_api_id" list={WhatsappList} title="WhatsApp Account"/>
						</Box>
					</HStack>

					{/*<HStack w="100%" mb="30px">
						<Box w="50%">
							<SelectField name="price_model_id" list={priceList} title="Pricing Model"/>		
						</Box>
					
					</HStack>*/}

					<Box mb="10px">
						<InputText name="server_ip" title="Server IP" placeholder="Please Enter 127.0.0.1"  />
					</Box>

					<Box mb="3px">
						<RadioInput name="auto_send" list={autoSendList} title="Auto Send Message using Other Channel" placeholder="Auto Send Message using Other Channel"  />
					</Box>	
					<Box  mb="10px">
						<Text fontSize="12px" color="#ABABAB">This will send message using the alternate channels incase the message delivery fails with primary channel</Text>
					</Box>
				 					 
				 	
				 	
				 	
			         <Button py="10px" px="25px" color="#fff" bg="#2BB673" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
					            mb={4}
					            
					            type='submit'
			          		>
			           		Update Customer
			          		</Button>
				</Form>

		</>
	)
}

export default Create;